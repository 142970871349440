/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';


/**
 *  Sengrid data
 */

export const GET_SENGRID_DATA = gql`
    query SengridDataWithLangs{
        sengridDataWithLangs{
            sengridGroupsID
            lang
            marketing
            newsletter
            apiKey
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }

        
    }
`;

export const SAVE_SENGRID_DATA = gql`
    mutation SaveSengridData($langs:[SengridLangsInput],$apiKey:String){
        saveSengridData(langs:$langs,apiKey:$apiKey)
    }
`;
