/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const SAVE_SETTINGS = gql`
    mutation SaveSettings($settings:SettingsInput){
        saveSettings(settings:$settings){
            webName
            logo   
            email
        }
    }
`;

/**
 *  Nastavení webu
 */

export const GET_SETTINGS = gql`
    query Settings{
        settings{
            webName
            logo   
            email
        }
        
    }
`;

export const SAVE_LOGO = gql`
    mutation SaveLogo($logo:Upload){
        saveLogo(logo:$logo)
    }
`;