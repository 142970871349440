/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 *  Nastavení webu
 */

export const GET_PAYU_DATA = gql`
    query PayUData{
        allDomains{
            domainID
            domainName
            posID
            clientID
            clientSecret
        }
        
    }
`;

export const SAVE_PAYU_DATA = gql`
    mutation SavePayuData($domains:[PayuInput]){
        savePayuData(domains:$domains)
    }
`;
