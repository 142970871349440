/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */

export const SEND_NEWSLETTER = gql`
    mutation SendOneNewsletter($newsletterID:ID,$subject:String!,$preheader:String!,$text:String!,$photo:Upload,$uploadedPhoto:String,$testEmail:String,$isTest:Boolean,$lang:String,$isPlanned:Int,$plannedDate: String){
        sendOneNewsletter(newsletterID:$newsletterID,subject:$subject,preheader:$preheader,text:$text,photo:$photo,uploadedPhoto:$uploadedPhoto,testEmail:$testEmail,isTest:$isTest,lang:$lang,isPlanned:$isPlanned,plannedDate:$plannedDate)
    }
`;

export const GET_NEWSLETTER_DATA = gql`
    query GetNewsletterData{
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allPlannedNewsletters{
            newsletterID
            subject
            plannedDateString
            plannedDate
            preheader
            photo
            text
            testEmail
            lang
        }
        emailCountInNewsletter{
            lang
            count
        }
        
    }
`;

export const DELETE_NEWSLETTERS = gql`
    mutation DeleteNewsletters($ids:[ID]){
        deleteNewsletters(ids:$ids)
    }
`;



