import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import {GET_PAYU_DATA} from '../Queries/payu.js';

class Settings {

    constructor(parent){
        this.parent = parent;
    }

    savePayu(savePayu){
        
        const data = this.parent.state.formData;
                     
        let domains = [];
        for(const domain of data.domains){
            domains.push({
                domainID:      domain.domainID,
                posID:         domain.posID,
                clientID:      domain.clientID,
                clientSecret:  domain.clientSecret,
            });
        }

        savePayu({
            variables:{
                domains
            }
        });
    }

    async getData(){
        
        var payuData = await this.parent.props.client.query({
            query:GET_PAYU_DATA
        });

        if(payuData.data.allDomains){
            this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,domains:payuData.data.allDomains}});
        }else{
            this.parent.setState({err:"Nepovedlo se načíst data pro payu."});
        }

    }
    
    formHandle(e,domainID){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        console.log(domainID);

        var domains = [...this.parent.state.formData.domains];
        for(let i in domains){
            if(domains[i].domainID == domainID){
                domains[i] = {...domains[i],[n]: v}
                console.log(domains);
            }
        }
        
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,domains}});
         
    }

}

export default Settings;