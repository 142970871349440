/**
 * soubor se seznamem veškerých graphql dotazů pro kategorie
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_MEMBERSHIP = gql`
    mutation AddEditMembership($membershipDurationID:ID,$active:Int,$length:Int,$autoPaymentsNumber:Int,$thanksLinkID:ID, $langs:[MembershipLangsInput]){
        addEditMembership(membershipDurationID:$membershipDurationID, active:$active,length:$length, autoPaymentsNumber:$autoPaymentsNumber, thanksLinkID:$thanksLinkID,langs:$langs){
            membershipDurationID
            length
            active
            autoPaymentsNumber
            name
            value
            description
            lang
        }
    }
`;

/**
 *  Úprava priority kategorie, když se přesouvají položky
 */
export const UPDATE_MEMBERSHIP_PRIORITY = gql`
    mutation updateMembershipPriority($membershipDurationID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateMembershipPriority(membershipDurationID:$membershipDurationID,fromIndex:$fromIndex,toIndex:$toIndex)
    }
`;

/**
 *  Data pro jednu kategorii
 */

export const GET_MEMBERSHIP = gql`
    query MembershipWithLangs($membershipDurationID: ID!){
        membershipWithLangs(membershipDurationID:$membershipDurationID){
            membershipDurationID
            length
            active
            autoPaymentsNumber
            thanksLinkID
            langs{
                lang
                name
                value
                vat
                description
                emailText
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            file
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allVats{
            value
        }
        allLinks(lang:"cz"){
            linkID
            name
        }
    }
`;

/**
 *  Seznam veškerých kategorií
 */
export const GET_MEMBERSHIPS = gql`
    query AllMemberships($lang: String!){
        allMemberships(lang: $lang){
            membershipDurationID
            length
            active
            autoPaymentsNumber
            name
            value
            description
            lang
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_MEMBERSHIPS = gql`
    mutation deleteMemberships($membershipDurationIDs:ID!) {
        deleteMemberships(membershipDurationIDs:$membershipDurationIDs)
    }
`;
