/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vložení kategorie
 */
export const ADD_EDIT_ARTICLE = gql`
    mutation AddEditArticle($articleID:ID!,$generateRandomRecommended:Boolean,$alone:Boolean,$withoutMainImage:Boolean,$photo:Upload,$linkIDs:[String],$langs:[ArticleLangsInput]){
        addEditArticle(articleID:$articleID,generateRandomRecommended:$generateRandomRecommended,alone:$alone,withoutMainImage:$withoutMainImage,photo:$photo,linkIDs:$linkIDs,langs:$langs){
            articleID
            publishDate
            title
            niceTitle
            perex
            text
            photo
            published
            lang
            langPublished{
                lang
                published
            }
        }
    }
`;

/**
 * publikování článku
 */
export const PUBLISH_ARTICLE = gql`
    mutation PublishArticle($articleID:ID!,$lang:String,$sendNewsletter:Boolean){
        publishArticle(articleID:$articleID,lang:$lang,sendNewsletter:$sendNewsletter)
    }
`;

export const UNPUBLISH_ARTICLE = gql`
    mutation UnPublishArticle($articleID:ID!,$lang:String){
        unpublishArticle(articleID:$articleID,lang:$lang)
    }
`;


/**
 *  Data pro jednu kategorii
 */

export const GET_ARTICLE = gql`
    query ArticleWithLangs($articleID: ID!){
        articleWithLangs(articleID:$articleID){
            articleID
            photo
            generateRandomRecommended
            alone
            linkIDs
            withoutMainImage
            langs{
                lang
                title
                publishDate
                published
                niceTitle
                perex
                text
                metaTitle
                metaKeywords
                metaDescription
            }    
        }
        allLanguageMutations(onlyEnabled:true){
            languageID
            title
            suffix
            generateNiceTitle
            langTitle
            decimal
            priority
            main
            currencyTitle
        }
        allServices(lang:"cz"){
            serviceID
            name
        }
        allForms(lang:"cz"){
            formID
            name
        }
    }
`;

/**
 *  Seznam veškerých articleů
 */
export const GET_ARTICLES = gql`
    query AllArticles($lang: String!,$offset:Int, $limit: Int){
        allArticles(lang: $lang,offset:$offset,limit:$limit){
            articleID
            title
            niceTitle
            perex
            text
            photo
            lang
            langPublished{
                lang
                published
            }
        }
    }
`;

/**
 * Smazání kategorie.
 */
export const DELETE_ARTICLES = gql`
    mutation deleteArticles($articleIDs:ID!) {
        deleteArticles(articleIDs:$articleIDs)
    }
`;


/**
 * vyhledání článku
 */
export const SEACRH_ARTICLES = gql`

    query SearchArticles($text: String!,$lang:String){
        searchArticles(text:$text,lang:$lang){
            title
            articleID
            photo 
        }

    }

`;


/**
 * data doporučeného článku
 */
export const GET_ARTICLE_RECOMMENDED = gql`
    query RecommendedArticles($articleID: ID!,$lang:String){
        recommendedArticles(articleID:$articleID,lang:$lang){
            articleRecommendedID
            article{
                title
                articleID
                photo
            }
        }

    }
`;

/**
 * smazání doporučeného článku
 */
export const DELETE_RECOMMENDED_ARTICLE = gql`
    mutation DeleteRecommendedArticles($recommendedArticleIDs: ID!){
        deleteRecommendedArticles(recommendedArticleIDs:$recommendedArticleIDs)
        reindexRecommendedArticlePriority
    }
`;

/**
 * přidání doporučeného článku
 */
export const ADD_RECOMMENDED_ARTICLE = gql`
    mutation AddRecommendedArticle($recommendedArticleID: ID!,$articleID: ID!){
        addRecommendedArticle(recommendedArticleID:$recommendedArticleID,articleID:$articleID){
            articleRecommendedID
            article{
                title
                articleID
                photo
                lang
            }
        }
    }
`;
/**
 * vyhledání článku
 */
export const UPDATE_RECOMMENDED_ARTICLE_PRIORITY = gql`

    mutation UpdateRecommendedArticlePriority($articleRecommendedID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateRecommendedArticlePriority(articleRecommendedID:$articleRecommendedID,fromIndex:$fromIndex,toIndex:$toIndex)
    }

`;



/**
 * data doporučeného článku
 */
export const GET_ARTICLE_MARKETINGS = gql`
    query ArticleMarketings($articleID: ID!,$lang:String){
        articleMarketings(articleID:$articleID,lang:$lang){
            articleMarketingID
            marketing{
                title
                marketingID
                photo
            }
        }

    }
`;

/**
 * smazání doporučeného článku
 */
export const DELETE_ARTICLE_MARKETING = gql`
    mutation DeleteArticleMarketings($articleMarketingIDs: ID!){
        deleteArticleMarketings(articleMarketingIDs:$articleMarketingIDs)
        reindexArticleMarketingPriority
    }
`;

/**
 * přidání doporučeného článku
 */
export const ADD_ARTICLE_MARKETING = gql`
    mutation AddArticleMarketing($marketingID: ID!,$articleID: ID!){
        addArticleMarketing(marketingID:$marketingID,articleID:$articleID){
            articleMarketingID
            marketing{
                title
                marketingID
                photo
                lang
            }
        }
    }
`;
/**
 * vyhledání článku
 */
export const UPDATE_ARTICLE_MARKETING_PRIORITY = gql`

    mutation UpdateArticleMarketingPriority($articleMarketingID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateArticleMarketingPriority(articleMarketingID:$articleMarketingID,fromIndex:$fromIndex,toIndex:$toIndex)
    }

`;



/**
 * data přiřazených služeb
 */
export const GET_ARTICLE_SERVICES = gql`
    query ArticleServices($articleID: ID!,$lang:String){
        articleServices(articleID:$articleID,lang:$lang){
            articleServiceID
            service{
                name
                serviceID
            }
        }

    }
`;

/**
 * smazání doporučeného článku
 */
export const DELETE_ARTICLE_SERVICE = gql`
    mutation DeleteArticleServices($articleServiceIDs: ID!){
        deleteArticleServices(articleServiceIDs:$articleServiceIDs)
        reindexArticleServicePriority
    }
`;

/**
 * přidání doporučeného článku
 */
export const ADD_ARTICLE_SERVICE = gql`
    mutation AddArticleService($serviceID: ID!,$articleID: ID!){
        addArticleService(serviceID:$serviceID,articleID:$articleID){
            articleServiceID
            service{
                name
                serviceID
                lang
            }
        }
    }
`;
/**
 * vyhledání článku
 */
export const UPDATE_ARTICLE_SERVICE_PRIORITY = gql`

    mutation UpdateArticleServicePriority($articleServiceID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateArticleServicePriority(articleServiceID:$articleServiceID,fromIndex:$fromIndex,toIndex:$toIndex)
    }

`;

/**
 * data přiřazených formulářů
 */
export const GET_ARTICLE_FORMS = gql`
    query ArticleForms($articleID: ID!,$lang:String){
        articleForms(articleID:$articleID,lang:$lang){
            articleFormID
            form{
                name
                formID
            }
        }

    }
`;

/**
 * smazání formulářů
 */
export const DELETE_ARTICLE_FORM = gql`
    mutation DeleteArticleForms($articleFormIDs: ID!){
        deleteArticleForms(articleFormIDs:$articleFormIDs)
        reindexArticleFormPriority
    }
`;

/**
 * přidání formuláře
 */
export const ADD_ARTICLE_FORM = gql`
    mutation AddArticleForm($formID: ID!,$articleID: ID!){
        addArticleForm(formID:$formID,articleID:$articleID){
            articleFormID
            form{
                name
                formID
                lang
            }
        }
    }
`;
/**
 * vyhledání formuláře
 */
export const UPDATE_ARTICLE_FORM_PRIORITY = gql`

    mutation UpdateArticleFormPriority($articleFormID:ID!,$fromIndex:Int!,$toIndex:Int!) {
        updateArticleFormPriority(articleFormID:$articleFormID,fromIndex:$fromIndex,toIndex:$toIndex)
    }

`;
