import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import {GET_SETTINGS,SAVE_LOGO} from '../Queries/web.js';

class Settings {

    constructor(parent){
        this.parent = parent;
    }

    async getSettings(){
        
        var settings = await this.parent.props.client.query({
            query:GET_SETTINGS
        });

        if(settings.data.settings){
            this.parent.setState({...this.parent.state,formData:settings.data.settings});
        }else{
            this.parent.setState({err:"Nepovedlo se načíst data pro nasavení."});
        }

    }

    saveSettings(saveSettings){

        const data = this.parent.state.formData;
        let notify = new Notification();   
        let helper = new Helper();

        
        if(helper.validateEmail(data.email)){
            saveSettings({
                variables:{
                    settings: {
                        webName:data.webName,
                        email:data.email
                    }
                }
            })
        }else{
            notify.setNotification(null,'Email není ve správném tvaru',false,true,this.parent.props.client);
        }

        

    }

    updateSettings(cache,response){

        const { settings } = cache.readQuery({ query: GET_SETTINGS });

        var sett = {...settings,
            webName: response.data.saveSettings.webName,
            email: response.data.saveSettings.email
        };
          
        cache.writeQuery({ 
            query:GET_SETTINGS,
            data:{
                settings:sett
            } 
        });
        
    }

    async saveLogo(e){

        let notify = new Notification(); 
        
        try{

            var photo = e.target.files[0]; 
            var logo = await this.parent.props.client.mutate({
                mutation:SAVE_LOGO,
                variables:{
                    logo:photo
                }
            });

            const { settings } = this.parent.props.client.readQuery({ query: GET_SETTINGS });

            var sett = {...settings,
                logo: logo.data.saveLogo
            };
            
            this.parent.props.client.writeQuery({ 
                query:GET_SETTINGS,
                data:{
                    settings:sett
                } 
            });

            notify.setNotification(null,"Úspěšně uloženo",true,false,this.parent.props.client);

            this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,logo: logo.data.saveLogo}});


        }catch(err){

            var helper = new Helper();
            err = helper.getApolloErrorText(err);

            notify.setNotification(null,err,false,true,this.parent.props.client);
        }

    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,[n]: v}});
         
    }

}

export default Settings;