/**
 *  komponenta pro štítky pro produkty
 */
 import React,{ Component,Fragment } from 'react';
 import { withApollo } from 'react-apollo';
 import {Query,Mutation} from 'react-apollo';
 import Loading from '../../../GlobalComponents/Loading';
 import Error from '../../../GlobalComponents/Error';
 import NoItems from '../../../GlobalComponents/NoItems';
 import {GET_INVOICE_CREDIT_NOTES,REMOVE_INVOICE_CREDIT_NOTE} from '../Queries/invoice.js';
 import notes from '../../../Public/Images/notes.svg';
 import notesD from '../../../Public/Images/notes_D.svg';
 import Helper from '../../../Library/helper';
 import {SERVER_URL} from '../../../Config/index';
 import moment from 'moment';
 import DatePicker,{ registerLocale } from 'react-datepicker';
 import "react-datepicker/dist/react-datepicker.css";
 import cs from 'date-fns/locale/cs';
 import InvoiceLib from '../Library/invoice';
 import Modal from 'react-modal';
 import remove from '../../../Public/Images/remove.svg';
 import Notification from '../../../Library/notification';
 import ModalNotification from '../../../GlobalComponents/ModalNotification';
 
 registerLocale('cs', cs);
 
 const INITIAL_STATE = {
     
     showExport:false,
     showAddCreditNote:false,
     loadingExport:true,
     progresPercentage:0,
     invoiceCount:0,
     completedCount:0,
     selectedInvoiceCreditNoteID:0,
     zipName:"",
     deleteCreditNote:null,
     invoiceListVariables:{
         limit:50,
         offset:0,
         lang:"cz",
         hasDeleted:true,
         dateFrom: moment().subtract(2,'months').startOf("day").toDate(),
         dateTo:moment().endOf("day").toDate(),
         selectedDomains:[],
         selectedServices:[],
         //dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD 00:00:00"),
         //dateTo:moment().format("YYYY-MM-DD 23:59:59"),
     }
 }
 
 class InvoiceCreditNote extends Component{
 
     constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.delCreditNote   = this.delCreditNote.bind(this);
       this.deleteCreditNote = this.deleteCreditNote.bind(this);
       
     }
 
     componentDidMount(){
         this.invoiceLib = new InvoiceLib(this);
     }
 
     closeExport(){
         this.setState({showExport:false,loadingExport:true});
     }
 
     delCreditNote(action){

        if(action){
            this.state.deleteCreditNote({
                variables:{
                    invoiceCreditNoteID: this.state.selectedInvoiceCreditNoteID
                }
            });
        }
        this.setState({
            deleteCreditNote:null,
            selectedInvoiceCreditNoteID:0,
            showDeleteNotifi:false
        });
     }
 
     deleteCreditNote(deleteCreditNote,invoiceCreditNoteID){

        this.setState({
            deleteCreditNote,
            showDeleteNotifi:true,
            selectedInvoiceCreditNoteID:invoiceCreditNoteID
        });
  
    }
 
     render(){
 
         const {selectedInvoiceCreditNoteID,invoiceListVariables,showExport,progresPercentage,invoiceCount,completedCount,loadingExport,zipName,showAddCreditNote} = this.state;
 
         return(
 
             <div id="settings" className="whole-container" >
                 
                 <div className="row" >
                     <div className="col-sm one">
                         
                         <Query 
                             query={GET_INVOICE_CREDIT_NOTES}
                             variables = {invoiceListVariables}
                             fetchPolicy = {'network-only'}
                         >
                             {({data,loading,error,fetchMore }) => {
                                 
                                 if(loading) return (<Loading />);
                                 if(error){
                                     const helper = new Helper(); 
                                     return (<Error text={helper.getApolloErrorText(error)} />);
                                 }
 
                                 return(
                                     <div className="card main">
                                         <div className="card-header">
                                             
                                             <div>
                                                 <div>
                                                     <strong>Od: </strong>
                                                     <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateFrom} onChange={date => this.invoiceLib.setDateFrom(date)} />
                                                     <strong> Do: </strong>
                                                     <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateTo} onChange={date => this.invoiceLib.setDateTo(date)} />
                                                     <button onClick={() => this.invoiceLib.invoiceCreditNoteExport()} className="btn btn-primary ml-2">Export dobropisů</button>
                                                     <button onClick={() => this.invoiceLib.invoiceCreditNoteExportToCSV()} className="btn btn-primary ml-2">Export do excelu</button>
                                                 </div>
                                                 <div className="d-flex align-items-center">
                                                     
                                                     {data.allDomains && data.allDomains.map((item,index) => {
                                                         
                                                         var checked = false;
                                                         for(let i in invoiceListVariables.selectedDomains){
                                                             if(invoiceListVariables.selectedDomains[i] == item.domainID){
                                                                 checked = true;
                                                                 break;
                                                             }
 
                                                         }
 
                                                         return (
                                                             <div className={(index > 0 ? "form-check ml-2" : "form-check")} key={index}>
                                                                 <input className="form-check-input" type="checkbox" checked={checked} value = {item.domainID} id={"domain" + index} onChange={(e) => this.invoiceLib.setDomain(e)} />
                                                                 <label className="form-check-label" htmlFor={"domain" + index} >
                                                                     {item.domainName}
                                                                 </label>
                                                             </div>
                                                         )
                                                     })}
                                                 </div>
                                                 <div className="d-flex align-items-center">
                                                     
                                                     {data.allServices && data.allServices.map((item,index) => {
 
                                                         var checked = false;
                                                         for(let i in invoiceListVariables.selectedServices){
                                                             if(invoiceListVariables.selectedServices[i] == item.serviceID){
                                                                 checked = true;
                                                                 break;
                                                             }
 
                                                         }
                                                         
                                                         return (
                                                             <div className={(index > 0 ? "form-check ml-2" : "form-check")}  key={index}>
                                                                 <input className="form-check-input" type="checkbox" id={"service" + index} checked={checked} value = {item.serviceID} onChange={(e) => this.invoiceLib.setService(e)} />
                                                                 <label className="form-check-label" htmlFor={"service" + index} >
                                                                     {item.name}
                                                                 </label>
                                                             </div>
                                                         )
                                                     })}
                                                 </div>
                                                 
                                             </div>
                                             
                                         </div>
                                         <div className="card-body">

                                            <Mutation 
                                                mutation={REMOVE_INVOICE_CREDIT_NOTE}
                                                update = {async (cache, response) => {

                                                    this.invoiceLib.updateCreditNoteListAfterDelete(cache,response,selectedInvoiceCreditNoteID,invoiceListVariables);
                                                    
                                                    let notify = new Notification();
                                                    notify.setNotification(cache,'Úspěšně odstraněno',true,false);
                                                    

                                                }}
                                                onCompleted = {(response) => {

                                                    
                                                    
                                                }}
                                                
                                            >
                                                {
                                                    (removeInvoiceCreditNote,{loading,error}) => {

                                                        if(error){
                                                            const helper = new Helper(); 
                                                            error = helper.getApolloErrorText(error);
                                                        }
                                                        
                                                        return(
    
                                                            <div>
                                                                <div className="data-list">
                                                                    
                                                                    <div className="data-list-item header">
                                                                        <div className="data-list-item-content">
                                                                            <div className="">Číslo dobropisu</div>
                                                                            <div className="text-center static">Číslo faktury</div>
                                                                            <div className="text-center static">Datum dobropisu</div>
                                                                            <div className="text-center static">Celková částka</div>
                                                                            <div className="text-right static small">Možnosti</div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {data.allFilteredInvoiceCreditNotes && data.allFilteredInvoiceCreditNotes.map((item,index) => {
                
                                                                        console.log(item);
                                                                                                                                                                                                
                                                                        return (
                                                                        
                                                                            <div key={index} className={"data-list-item-content " + (item.deleted == 1 ? "deleted" : "")}>
                                                                                <div className="">{item.creditNoteNumber}</div>
                                                                                <div className="text-center static">{item.invoice.invoiceNumber}</div>
                                                                                <div className="text-center static">{moment(item.creditNoteDate).format("D.M.YYYY")}</div>
                                                                                <div className="text-center static">{(-1) * item.invoice.totalPrice} {item.invoice.currency}</div>
                                                                                <div className="text-right static small">
                                                                                    
                                                                                    <a target="_blank" href={SERVER_URL + "/generate/invoice-credit-note/" + item.invoiceCreditNoteID}><img title="tisk dobropisu" className="print-icon" src={notesD} /></a>  
                                                                                    {item.deleted == 0 ?
                                                                                        <img onClick={() => this.deleteCreditNote(removeInvoiceCreditNote,item.invoiceCreditNoteID)} className="remove-icon ml-2" src={remove} />
                                                                                    :null}
                                                                                </div>
                                                                            </div>
                                                                                    
                                                                        )
                                                                        
                                                                    })} 
                
                                                                </div>
                                                                
                                                                {data.allFilteredInvoiceCreditNotes && data.allFilteredInvoiceCreditNotes.length == 0 ?
                                                                    <NoItems text={"V tomto období se momentálně nenachází žádné dobropisy."} />
                                                                :
                                                                    <div className="text-center p-5">
                                                                        <button className="btn btn-primary" onClick={() => fetchMore({
                                                                            variables: {
                                                                                offset: data.allFilteredInvoiceCreditNotes.length
                                                                            },
                                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                                if (!fetchMoreResult) return prev;
                                                                                return Object.assign({}, prev, {
                                                                                    allFilteredInvoiceCreditNotes: [...prev.allFilteredInvoiceCreditNotes, ...fetchMoreResult.allFilteredInvoiceCreditNotes]
                                                                                });
                                                                            }
                                                                        })}> Načíst další dobropisy </button>
                                                                    </div> 
                
                                                                }
                                                                
                                                            </div>
                                                        )
                                                    }
                                                }
                                            </Mutation>
                                         </div>
                                     </div>
                                     );
                                 }
                             }
                         </Query>
 
                     </div>
                 </div> 	
 
                 <Modal
                     isOpen={showExport}
                     className="Modal__Bootstrap modal-dialog modal-lg"
                     closeTimeoutMS={150}
                     onRequestClose={() => this.closeExport()}
                 >
 
                     <div className="modal-content">
                         <div className="modal-header">
                             <h4 className="modal-title">Export faktur</h4>
                         </div>
                         <div className="modal-body">
                             {loadingExport ? 
                                 <Fragment>
                                     <Loading />
                                     <div className="progress" style={{height:"30px"}}>
                                         <div className="progress-bar" role="progressbar" style={{width:Math.round((completedCount / invoiceCount * 100 )) + "%"}} aria-valuenow={Math.round((completedCount / invoiceCount * 100 ))} aria-valuemin="0" aria-valuemax="100">{completedCount}/{invoiceCount}</div>
                                     </div>
                                 </Fragment>
                                 :
                                 <div className="text-center pt-5 pb-5"><a style={{fontSize:"30px"}} href={SERVER_URL + "/Public/InvoiceCreditNotes/Export/" + zipName}>STÁHNOUT DOBROPISY</a></div>
                             }
                         </div>
                     </div>
 
 
                 </Modal>

                 {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat tento dobropis ?'} callback={this.delCreditNote} /> }

             </div>
         );
 
     }
 
 }
 
 export default withApollo(InvoiceCreditNote);