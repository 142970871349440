import Notification from '../../../Library/notification';
import Helper from '../../../Library/helper';
import {GET_SENGRID_DATA} from '../Queries/sengrid.js';

class Settings {

    constructor(parent){
        this.parent = parent;
    }

    saveSengridData(saveSengridData){
        
        const data = this.parent.state.formData;
                     
        let langs = [];
        for(const lang of data.langs){
            langs.push({
                sengridGroupsID: lang.sengridGroupsID,
                lang:            lang.lang,
                newsletter:      lang.newsletter,
                marketing:       lang.marketing,
            });
        }

        saveSengridData({
            variables:{
                apiKey:data.apiKey,
                langs
            }
        });
    }

    /**
     * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
     */

    async getData(){

        let notify = new Notification(); 
        
        try{

            var data = await this.parent.props.client.query({ 
                query: GET_SENGRID_DATA,
                errorPolicy:"all", 
                fetchPolicy: 'network-only'
            });
                 
            if(data.data){                  
                if(data.data.sengridDataWithLangs){    
                    let langs = this.completeLangsArray(data.data.sengridDataWithLangs,data.data.allLanguageMutations);     
                    this.parent.setState({
                        allLanguageMutations: data.data.allLanguageMutations,
                        formData:{...this.parent.state.formData,
                            langs:langs,
                            apiKey:data.data.sengridDataWithLangs[0].apiKey
                        }
                    });
                }else{
                    this.parent.setState({ apolloError:""});
                }
            }

        }catch(err){

            var helper = new Helper();
            err = helper.getApolloErrorText(err);

            notify.setNotification(null,err,false,true,this.parent.props.client);
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    sengridGroupsID:0,
                    marketing:"",
                    newsletter:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    

    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangObjectHandle(e){
	  
        const t = e.target;
        const v = t.value;
        
        for(const lang of this.parent.state.allLanguageMutations){
            if(lang.languageID == v){
                this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix}});
            }
        }

              
    }
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} propertyName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (propertyName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[propertyName];
            }
        }
        return "";
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,[n]: v}});
         
    }

}

export default Settings;