/*
    Výběr veškerých kategorií
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import { withApollo } from 'react-apollo';
import {SAVE_PAYU_DATA} from '../Queries/payu';
import PayU from '../Library/payu';
import Notification from '../../../Library/notification';

const INITIAL_STATE = {
    error:"",
    formData:{
        domains:[]
    }

}

class Payu extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.payuLib = new PayU(this);
    }

    componentDidMount(){
        this.payuLib.getData();
    }

    render(){
        
        var {formData,err} = this.state;
        var {domains} = formData;

        return(

            <Mutation 
                mutation={SAVE_PAYU_DATA}
                errorPolicy = "all"
                update = {async (cache, response) => {
                   
                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    
                }}      
            >
            {
                (savePayu,{loading, error}) => {

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <div id="settings" className="whole-container" >
                            
                            <div className="row" >
                                <div className="col-sm one">
                                    <div className="card main">
                                        
                                        <div className="card-header d-flex align-items-center">
                                            Nastavení PayU
                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.payuLib.savePayu(savePayu)}>Uložit</button>
                                        </div>
                                        <div className="card-body">

                                            {error ?
                                                <Error text={err} />

                                                :
                                                <Fragment>

                                                    <div className="data-list">
                                                        
                                                        <div className="data-list-item header">
                                                            <div className="data-list-item-content">
                                                                <div>Doména</div>
                                                                <div className="text-center">POS_ID</div>
                                                                <div className="text-center">CLIENT_ID</div>
                                                                <div className="text-center">CLIENT_SECRET</div>
                                                            </div>
                                                        </div>
         
                                                        <div>
                                                            {domains && domains.map((item,index) => {
                                                                
                                                                return (
                                                                    
                                                                    <div key={index} className="data-list-item">
                                                                        <div className="data-list-item-content categories">
                                                                            <div>
                                                                                {item.domainName}
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <input value={item.posID} onChange={(e) => this.payuLib.formHandle(e,item.domainID)} name="posID" className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <input value={item.clientID} onChange={(e) => this.payuLib.formHandle(e,item.domainID)} name="clientID" className="form-control" type="text" />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <input value={item.clientSecret} onChange={(e) => this.payuLib.formHandle(e,item.domainID)} name="clientSecret" className="form-control" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                
                                                            })}
                                                        </div>
                                                                    
                                                    
                                                    </div>
                                                    
                                                </Fragment>
                                            }
            
                                        </div>
                                    </div>
                                </div>
                            </div> 			
                        </div>
                    )
                }
            }
            </Mutation>
        );

    }

}


export default withApollo(Payu);