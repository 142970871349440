/*
    Výběr veškerých kategorií
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import { withApollo } from 'react-apollo';
import {SAVE_SENGRID_DATA} from '../Queries/sengrid';
import SengridLib from '../Library/sengrid';
import Notification from '../../../Library/notification';

const INITIAL_STATE = {
    error:"",
    formData:{
        apiKey:"",
        selectedLang:'cz',
        langs:[],
    },
    allLanguageMutations:[]

}

class Sengrid extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.sengridLib = new SengridLib(this);

    }

    componentDidMount(){
        this.sengridLib.getData();
    }

    render(){
        
        var {formData,err,allLanguageMutations} = this.state;

        return(

            <Mutation 
                mutation={SAVE_SENGRID_DATA}
                errorPolicy = "all"
                update = {async (cache, response) => {
                    
                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    
                }}      
            >
            {
                (saveSengridData,{loading, error}) => {

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    const {selectedLang,apiKey} = this.state.formData;

                    return(
                        <div id="settings" className="whole-container" >
                            
                            <div className="row" >
                                <div className="col-sm one">
                                    <div className="card main">
                                        
                                        <div className="card-header d-flex align-items-center">
                                            Nastavení Sengridu
                                            <div className="ml-auto mr-2">
                                                <select className="form-control thiner" name="selectedLang" onChange={(e) => this.sengridLib.formLangObjectHandle(e)}>
                                                    {allLanguageMutations && allLanguageMutations.map((item,index) => (
                                                        <option key={index} value={item.languageID}>{item.suffix}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <button className="btn btn-primary btn-thiner" onClick={() => this.sengridLib.saveSengridData(saveSengridData)}>Uložit</button>
                                        </div>
                                        <div className="card-body">

                                            {error ?
                                                <Error text={err} />

                                                :
                                                <Fragment>

                                                    <div className="row">   
                                                        <div className="col-12">
                                                            <label className="input-label">SENGRID API KEY </label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="apiKey" value={apiKey} onChange={(e) => this.sengridLib.formHandle(e)} />
                                                            </div>
                                                        </div>           
                                                    </div>

                                                </Fragment>
                                            }
            
                                        </div>
                                    </div>
                                </div>
                            </div> 			
                        </div>
                    )
                }
            }
            </Mutation>
        );

    }

}


export default withApollo(Sengrid);