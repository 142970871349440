/*
    Výběr veškerých kategorií
*/
import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import { withApollo } from 'react-apollo';
import {SAVE_SETTINGS} from '../Queries/web';
import Settings from '../Library/web';
import Notification from '../../../Library/notification';
import {SERVER_URL} from '../../../Config/index';

const INITIAL_STATE = {
    error:"",
    formData:{
        logo:"",
        webName:"",
        email:""
    }

}

class Web extends Component{


    constructor(props){
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount(){
        this.settingsLib = new Settings(this);
        this.settingsLib.getSettings();
    }

    render(){
        
        var {formData,err} = this.state;

        return(

            <Mutation 
                mutation={SAVE_SETTINGS}
                errorPolicy = "all"
                update = {async (cache, response) => {

                    this.settingsLib.updateSettings(cache,response);
                    
                    let notify = new Notification();
                    notify.setNotification(cache,'Úspěšně uloženo',true,false);
                    
                }}      
            >
            {
                (saveSettings,{loading, error}) => {

                    if(error){
                        const helper = new Helper(); 
                        err = helper.getApolloErrorText(error);
                    }

                    return(
                        <div id="settings" className="whole-container" >
                            
                            <div className="row" >
                                <div className="col-sm one">
                                    <div className="card main">
                                        
                                        <div className="card-header d-flex align-items-center">
                                            Nastavení webu
                                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.settingsLib.saveSettings(saveSettings)}>Uložit</button>
                                        </div>
                                        <div className="card-body">

                                            {error ?
                                                <Error text={err} />

                                                :
                                                <Fragment>

                                                    <div className="row">  
                                                        <div className="col">
                                                            <label className="input-label">Logo </label>
                                                            <div className="form-group d-flex">
                                                                <div style={{padding:"10px",border:"1px solid #ddd"}}>
                                                                    <img style={{height:"50px"}} src={SERVER_URL + "/Public/Images/" + formData.logo} />
                                                                </div>
                                                                <div className="input-group align-items-center ml-2" style={{width:'130px'}}>
                                                                    <div className="custom-file">
                                                                        
                                                                        <input
                                                                            type="file"
                                                                            className="custom-file-input"
                                                                            id="customFile"
                                                                            name="file"
                                                                            required
                                                                            multiple
                                                                            onChange={(e) => this.settingsLib.saveLogo(e)}
                                                                        />
                                                                        <label className="custom-file-label logo no-margin" htmlFor="customFile">Vyberte logo</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                    <div className="row">             
                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">Název webu </label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="webName" value={formData.webName} onChange={(e) => this.settingsLib.formHandle(e)} />
                                                            </div>
                                                        </div> 
                                                        <div className="col-12 col-sm-4">
                                                            <label className="input-label">Kontaktní email </label>
                                                            <div className="form-group">
                                                                <input className="form-control" type="text" name="email" value={formData.email} onChange={(e) => this.settingsLib.formHandle(e)} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Fragment>
                                            }
            
                                        </div>
                                    </div>
                                </div>
                            </div> 			
                        </div>
                    )
                }
            }
            </Mutation>
        );

    }

}


export default withApollo(Web);