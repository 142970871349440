/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

 import Notification from '../../../Library/notification';
 import {GET_CAMPAIGN_EMAILS, GET_CAMPAIGN_EMAIL,SEND_CAMPAIGN_TEST_EMAIL} from '../Queries/campaignEmail.js';
 
 class CampaignEmail {
 
     constructor(parent){
         this.parent = parent;
     }
 
     /**
      * Přidání/Editace videa
      * @param {*} addCampaignEmail funkce z apollo, pomocí které se posílají data na server
      */
 
     addCampaignEmail = (addCampaignEmail,campaignID) => {
 
        const data = this.parent.state.formData;
        let notify = new Notification();   

        let langs = [];
        for(const lang of data.langs){
            langs.push({
                subject: lang.subject,
                text: lang.text,
                lang:lang.lang,
                preheader:lang.preheader
            });
        }

        addCampaignEmail({
            variables:{
                campaignEmailID:data.campaignEmailID,
                campaignID,
                hours: parseInt(data.hours),
                testEmail:data.testEmail,
                langs
            }
        });
         
                
     } 
 
 
     /**
      * 
      * @param {*} cache apollo cache, kde jsou uložena data
      * @param {*} response hodnota, která se vrátila po smazání objednávky 
      */
 
     updateAfterDelete(cache, response,listVariables){
 
         var resp = response.data.deleteCampaignEmails.split(",");
 
         const { allCampaignEmails } = cache.readQuery({ query: GET_CAMPAIGN_EMAILS,variables:listVariables,});
         var arr = [...allCampaignEmails];
         
         resp.forEach((it,ind) => {
             arr.forEach((item,index) => {
                 if(item.campaignEmailID == it){
                     arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                 }
             });
         }) 
         
         cache.writeQuery({ 
             query:GET_CAMPAIGN_EMAILS,
             variables:listVariables,
             data:{
                 allCampaignEmails: [...arr]
             } 
         });
 
     }
     
     /**
      * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
      * @param {Object} cache apollo cahe v které jsou oložena data
      * @param {Object} response položka, která byla vložena nebo upravována
      * @param {Number} selectedID id položky
      * @param {Object} variables parametry posílané do query
      */
 
     updateList(cache,response,selectedID,variables){
 
         const { allCampaignEmails } = cache.readQuery({ query: GET_CAMPAIGN_EMAILS,variables:variables});
         
         if(!(selectedID && selectedID != 0)){
 
             cache.writeQuery({ 
                 query:GET_CAMPAIGN_EMAILS,
                 variables:variables,
                 data:{
                    allCampaignEmails: [...allCampaignEmails,response.data.addEditCampaignEmail]
                 } 
             });
 
         }else{
 
             var arr = [...allCampaignEmails];
             
             arr.forEach((item,index) => {
                 
                 if(item.campaignEmailID == response.data.addEditCampaignEmail.campaignEmailID){
                     arr[index].campaignEmailID  = response.data.addEditCampaignEmail.campaignEmailID;
                     arr[index].subject          = response.data.addEditCampaignEmail.subject;
                 }
 
             });
             cache.writeQuery({ 
                 query:GET_CAMPAIGN_EMAILS,
                 variables:variables,
                 data:{
                     allCampaignEmails: arr
                 } 
             });
         }
     }
     
     /**
      * při inicializaci přidání kategorie nebo její úpravy vytáhne potřebná data k dopravě
      */
 
     async getData(){
 
         var campaignEmailID = 0;
         if(this.parent.props.selectedCampaignEmailID)campaignEmailID = this.parent.props.selectedCampaignEmailID;
         
         var data = await this.parent.props.client.query({ 
             query: GET_CAMPAIGN_EMAIL,
             errorPolicy:"all",
             variables:{campaignEmailID:campaignEmailID}, 
             fetchPolicy: 'network-only'
         });
         
         
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
         
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.campaignEmailWithLangs){    
                let langs = this.completeLangsArray(data.data.campaignEmailWithLangs.langs,data.data.allLanguageMutations);     
                this.parent.setState({
                    apolloError:"",
                    selectedCampaignEmailID:campaignEmailID,
                    allLanguageMutations: data.data.allLanguageMutations,
                    formData:{...this.parent.state.formData,
                        campaignEmailID:  data.data.campaignEmailWithLangs.campaignEmailID,
                        hours:            data.data.campaignEmailWithLangs.hours,
                        testEmail:        data.data.campaignEmailWithLangs.testEmail,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ 
                    apolloError:"",
                    allLanguageMutations: data.data.allLanguageMutations,
                });
            }
        }

        //kdyz pridavame zaznam
        if(campaignEmailID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);            
            
            this.parent.setState({
                allLanguageMutations: data.data.allLanguageMutations,
                formData:{...this.parent.state.formData,
                    langs:langs,
                    testEmail:  data.data.settings.email,
                }
            });
        }
 
    }

    sendEmail(lang,subject,preheader,text,testEmail){

        this.parent.props.client.mutate({ 
            mutation: SEND_CAMPAIGN_TEST_EMAIL,
            variables:{
                lang,
                subject,
                preheader,
                text,
                testEmail
            }, 

        });

        let notify = new Notification();  
        notify.setNotification(null,"Úspěšně posláno",true,false,this.parent.props.client);

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {

        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    if(!lang.text) lang.text = "";
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    subject:"",
                    text:"",
                    preheader:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

     checkServices = (e,serviceID) => {

        var checked = e.target.checked;
        var arr = [...this.parent.state.formData.selectedServices];

        if(!checked){
            for(let i in this.parent.state.formData.selectedServices){
                if(this.parent.state.formData.selectedServices[i] == serviceID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [serviceID,...arr];
        }
        
        this.parent.setState({formData:{...this.parent.state.formData,
            selectedServices:arr
        }});
  
    }

    checkForms = (e,formID) => {

        var checked = e.target.checked;
        var arr = [...this.parent.state.formData.selectedForms];

        if(!checked){
            for(let i in this.parent.state.formData.selectedForms){
                if(this.parent.state.formData.selectedForms[i] == formID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [formID,...arr];
        }
        
        this.parent.setState({formData:{...this.parent.state.formData,
            selectedForms:arr
        }});
  
    }
  
     /**
      * funkce ukládá data, která byla vložena do campaignEmailuláře
      * @param {*} e ukazatel na element
      */
     
     formHandle(e){
       
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
               
     }
     /**
      * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formLangObjectHandle(e){
       
         const t = e.target;
         const v = t.value;
         
         for(const lang of this.parent.state.allLanguageMutations){
             if(lang.languageID == v){
                 this.parent.setState({formData:{...this.parent.state.formData,selectedLang: lang.suffix}});
             }
         }
 
               
     }
     
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
      * @param {*} e ukazatel na element
      */
     
     formLangHandle(e){
         
         const t = e.target;
         const v = t.type === 'checkbox' ? t.checked : t.value;
         const n = t.name;
         
         let langs       = this.parent.state.formData.langs;
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[n] = v;
             }
         }
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
          
     }
 
     /**
      * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
      * @param {String} data data tinyMCE editoru
      * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
      */
     
     formLangHandleEditor(content,paramName){
         
         let langs       = this.parent.state.formData.langs;
 
         for(const lang of langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 lang[paramName] = content;
             }
         }
 
         this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
     }
 
     /**
      * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
      * @param {String} propertyName - název proměnné v objektu lang
      * 
      * @returns property value
      */
     getLangValue = (propertyName) => {
         for(const lang of this.parent.state.formData.langs){
             if(lang.lang == this.parent.state.formData.selectedLang){
                 return lang[propertyName];
             }
         }
         return "";
     }
     
 
     
 
 }
 
 export default CampaignEmail;